<template>
  <!--          Exercise Card     -->
  <div
    class="cursor-pointer border-b border-secondary-250 last:border-0 mt-2 first:mt-0">
    <div
      class="flex gap-2 justify-between rounded-md px-6 py-4 mb-2 hover:bg-secondary-75"
      :class="{ 'bg-secondary-100': selected }">
      <div class="flex gap-2">
        <div>
          <p class="font-bold text-secondary-900">
            {{ getExerciseTitle(exercise) ?? exercise.title }}
          </p>
          <span class="text-secondary-800 text-sm">
            {{ getSubmissionNo }} תלמידים לבדיקה
          </span>
        </div>
      </div>
      <NoChatMessage />
    </div>
  </div>
  <!--          Exercise Card     -->
</template>
<script setup lang="ts">
import { useRoute } from 'vue-router';
import { useStudentsStore } from '/@/app/store/students';
import { getExerciseTitle } from '/@/views/components/LessonSubmission/utils';
import NoChatMessage from '/@/assets/no-chat-message.svg';
import { computed } from 'vue';
const route = useRoute();
const studentsStore = useStudentsStore();
const props = defineProps({
  exercise: {
    type: Object,
    default: () => ({}),
  },
  selected: {
    type: Boolean,
    default: false,
  },
});

const getSubmissionNo = computed(
  () =>
    props.exercise.studentsToReviewCount ??
    studentsStore.submissions
      .filter(
        s =>
          s.exercise === props.exercise.id &&
          s.lesson === route.params.lesson &&
          s.status == 'awaiting_review' &&
          s.records?.some(q => q.type !== 'decision_making_game'),
      )
      .flatMap(s => s.participants).length,
);
</script>
