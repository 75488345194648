import { computed, ComputedRef, h, Ref } from 'vue';
import { IProjectEnrollment } from '/@/app/models/interfaces';
import { useArrayMap } from '@vueuse/core';
import { renderBlock } from '@nwire/amit-design-system';

export const useProjectSubmission = (
  enrollments: Ref<IProjectEnrollment[]>,
  student: Ref<string>,
  stage: Ref<string>,
  stageData: Ref<any>,
) => {
  const hasSubmission = (stage: string) => {
    const studentEnrollment = enrollments.value?.find(
      enrollment => enrollment.student === student.value,
    );
    const enrollmentSummaryStage = studentEnrollment?.summary?.find(
      item => item.stage.id === stage,
    );
    return enrollmentSummaryStage?.status !== 'in_progress';
  };

  const studentEnrollment: ComputedRef<IProjectEnrollment | undefined> =
    computed(() =>
      enrollments.value?.find(
        enrollment => enrollment.student === student.value,
      ),
    );

  const submissionRecords = computed(() => {
    return (
      studentEnrollment.value?.summary?.find(
        item => item.stage.id === stage.value,
      )?.records || []
    );
  });

  const getSubmissionAnswer = id => {
    return submissionRecords.value.find(record => record.question === id)
      ?.answer;
  };

  const questionBlocks = computed(() => {
    return (
      stageData.value?.sections?.flatMap(section =>
        section.pages.flatMap(page => {
          const pageHasQuestion = page.blocks.some(
            block => block.type === 'question',
          );
          return pageHasQuestion ? page.blocks : [];
        }),
      ) || []
    );
  });

  const PageContent = () => {
    const PageBlocks = useArrayMap(questionBlocks.value, (block: any) =>
      renderBlock({
        editable: false,
        block,
        state: getSubmissionAnswer(block.id) || [],
      }),
    );
    return h(
      'div',
      {
        class:
          'flex flex-col space-y-3 text-right px-5 pb-20 overflow-y-auto h-full w-full page-content',
      },
      PageBlocks.value,
    );
  };

  return {
    submissionRecords,
    studentEnrollment,
    PageContent,
    hasSubmission,
  };
};
