<template>
  <!--          student Card     -->
  <div
    class="cursor-pointer border-b border-secondary-250 last:border-0 mt-2 first:mt-0">
    <div
      class="flex gap-2 justify-between rounded-md px-6 py-4 mb-2 hover:bg-secondary-75"
      :class="{ 'bg-secondary-100': selected }">
      <div class="flex gap-2">
        <img
          :src="student.avatar ?? defaultImage"
          alt="student avatar"
          class="rounded-full w-9 h-9"/>
        <div>
          <p class="font-bold text-secondary-900 text-sm">
            {{ student.fullName }}
          </p>
          <span class="text-secondary-800 text-xs">
            {{ student.submissionsCount ?? getSubmissionNo }} תרגילים לבדיקה
          </span>
        </div>
      </div>
      <NoChatMessage />
    </div>
  </div>
  <!--          student Card     -->
</template>
<script setup lang="ts">
import NoChatMessage from '/@/assets/no-chat-message.svg';
import { useStudentsStore } from '/@/app/store/students';
import { useRoute } from 'vue-router';
import { computed } from 'vue';
const route = useRoute();
const studentsStore = useStudentsStore();

const defaultImage =
  'https://res.cloudinary.com/dcodkxpej/image/upload/v1725192676/default-avatar-image_adpnhe.png';

const props = defineProps({
  student: {
    type: Object,
    default: () => ({}),
  },
  selected: {
    type: Boolean,
    default: false,
  },
});

const getSubmissionNo = computed(
  () =>
    studentsStore.submissions.filter(
      s =>
        s.participants.includes(props.student.id) &&
        s.lesson === route.params.lesson &&
        s.status == 'awaiting_review' &&
        s.records?.some(q => q.type !== 'decision_making_game'),
    ).length,
);
</script>
