<template>
  <div
    class="flex flex-col overflow-hidden"
    :style="{
      height: 'calc(100vh - 60px)',
    }">
    <div class="flex flex-col w-[32%]">
      <span
        class="flex gap-1 cursor-pointer text-secondary-600"
        @click="
          router.push({
            name: 'course-progress',
            params: { group, course },
            query: { view: 'grades' },
          })
        ">
        <ChevronRight :size="16" />
        <span
          class="text-start text-secondary-600 text-sm overflow-hidden whitespace-nowrap text-ellipsis">
          {{ courseObj?.course?.caption }} / {{ currentGroup?.name }}
        </span>
      </span>
      <PVSelect
        v-model="selectedLesson"
        filter
        empty-filter-message="לא נמצאו תוצאות"
        :pt="dropdownPT"
        :options="lessonsOptions"
        option-label="title"
        placeholder=""
        class="w-full md:w-14rem">
        <template #value="{ value, placeholder }">
          <p
            v-if="value"
            :title="value.title"
            :dir="courseObj?.course?.locale != 'he' ? 'ltr' : ''"
            class="text-start text-secondary-900 text-lg font-bold overflow-hidden whitespace-nowrap text-ellipsis">
            {{ value.title }}
          </p>
          <p v-else>{{ placeholder }}</p>
        </template>
        <template #dropdownicon>
          <div
            class="flex justify-center items-center bg-white hover:bg-secondary-75 rounded-sm w-7 h-7">
            <ChevronDown :size="16" />
          </div>
        </template>
      </PVSelect>
    </div>
    <div
      class="flex-1 flex overflow-hidden h-full w-full gap-2.5 border-0 mt-5">
      <div class="h-full bg-white rounded-lg w-[32%]">
        <SelectionPanel
          v-model:active-panel="reviewPer"
          v-model:active-student="student"
          v-model:active-exercise="exercise"
          :students="students"
          :exercises="exercises"
          :project-stages="projectStages"
          :project-enrollments="projectEnrollments"
          :is-project="isProject"></SelectionPanel>
      </div>
      <div class="w-[68%]">
        <DisplayPanel
          :students="students"
          :is-project="isProject"
          :project-enrollments="projectEnrollments"
          :project-id="projectId"></DisplayPanel>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useStudentsStore } from '/@/app/store/students';
import { computed, onMounted, ref, watch, watchEffect } from 'vue';
import { useAccountStore } from '/@/app/store/account';
import { useRoute, useRouter } from 'vue-router';
import { useCourseStore } from '/@/app/store/course';
import DisplayPanel from '/@/views/components/LessonSubmission/DisplayPanel.vue';
import SelectionPanel from '/@/views/components/LessonSubmission/SelectionPanel.vue';
import { lessonDropdownPT } from '/@/views/components/LessonSubmission/styles';
import PVSelect from 'primevue/select';
import { useRouteQuery, useRouteParams } from '@vueuse/router';
import { ChevronDown, ChevronRight } from 'lucide-vue-next';
import { useCourse } from '../../composables/useCourse';
import { keyBy } from 'lodash-es';

const accountStore = useAccountStore();
const studentsStore = useStudentsStore();
const courseStore = useCourseStore();

const route = useRoute();
const router = useRouter();

let currentGroup = ref<any>(null);
const selectedLesson = ref();

const reviewPer = useRouteQuery<any>('review_per', 'exercise');
const student = useRouteQuery<any>('student', null);
const exercise = useRouteQuery<any>('exercise');
const lesson = useRouteParams('lesson');

const group = route.params.group as string;
const course = route.params.course as string;

const {
  projectId,
  lessonsOptions,
  courseModule: courseObj,
  courseData,
  selectedLessonById,
  projectStages,
  projectEnrollments,
  setEnrollment,
} = useCourse(group, course);

const isProject = computed(
  () => !!projectId.value && projectId.value === lesson.value,
);

const projectEnrollmentsByStudentId = computed(() =>
  keyBy(projectEnrollments.value, 'student'),
);

const students = computed(() =>
  currentGroup.value?.students?.map(st => ({
    ...st,
    submissionsCount: isProject.value
      ? projectEnrollmentsByStudentId.value[st.id]?.summary?.filter(
          ({ status }) => status === 'awaiting_review',
        )?.length
      : null,
  })),
);

const exercises = computed(() =>
  isProject.value ? projectStages.value : courseStore.exercises,
);
// const activePanel = ref(reviewPer.value|| 0);

watch([projectId, selectedLesson], () => {
  if (projectId.value && selectedLesson.value?.id === projectId.value) {
    setEnrollment();
  }
});

//
// watch(
//   () => activePanel.value,
//   () => (reviewPer.value = reviewBy[activePanel.value]),
// );
watch(
  () => selectedLesson.value,
  () => {
    if (selectedLesson.value?.id) {
      router.push({
        ...route,
        params: {
          ...route.params,
          lesson: selectedLesson.value.id,
        },
      });
    }
  },
);

const load = () => {
  currentGroup.value = accountStore.groups.find(
    acGroup => acGroup.id === group,
  );
  if (!accountStore.selectedGroup) {
    accountStore.selectedGroup = currentGroup;
  }

  studentsStore.setCourseModule(group, course);
  courseStore.fetchCourse(course);

  if (!isProject.value) {
    courseStore.fetchExercises(course, lesson.value);
    studentsStore.fetchSubmissionsByLesson(group, lesson.value);
    studentsStore.fetchEnrollments({ group, course });
  }
};

watchEffect(() => {
  if (!courseObj.value || Object.keys(courseData.value).length === 0) return;
  selectedLesson.value = selectedLessonById(lesson.value as string);
});

onMounted(() => {
  load();
});

watch(
  () => [exercises.value, selectedLesson.value?.id],
  () => {
    if (!exercises.value?.length) return;
    if (!exercises.value?.find(ex => ex.id === exercise.value)) {
      exercise.value = exercises.value[0]?.id;
    }
  },
);

watch(
  () => [students.value],
  () => {
    if (!students.value.find(st => st.id === student.value)) {
      student.value = students.value[0]?.id;
    }
  },
);

const dropdownPT = computed(() =>
  lessonDropdownPT(courseObj.value?.course?.locale),
);
</script>
