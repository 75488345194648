import { reviewSubmission } from '/@/app/services/projects';
import { useAccountStore } from '/@/app/store/account';

export const useProjectFeedback = (
  studentEnrollmentId: string,
  student: string,
  stageID: string,
  projectID: string,
) => {
  const accountStore = useAccountStore();

  const sendFeedbackProject = (
    feedback: string,
    onSuccessSubmission: () => void,
  ) => {
    let data: any = {
      stage: stageID,
      title: 'תגובה חדשה ממורה',
      content: feedback,
      reviewed_by: accountStore.user.id,
      student: student,
    };

    const { onSuccess } = reviewSubmission(
      projectID,
      studentEnrollmentId,
      data,
    );
    onSuccess(async () => {
      onSuccessSubmission();
    });
  };

  return {
    sendFeedbackProject,
  };
};
