<template>
  <LoadingOverlay v-model:active="courseStore.loading" :is-full-page="false" />
  <Transition v-if="!courseStore.loading" name="slide">
    <div class="h-full flex flex-col gap-3">
      <div class="flex justify-between h-8 items-center shrink-0">
        <div v-if="reviewPer == 'student'" class="flex items-center">
          <span class="text-sm text-secondary-900">
            כל התרגילים ({{ filterExercises?.length }})
          </span>
        </div>
        <div v-else class="flex justify-between items-center h-7.5">
          <div v-if="!isProject" class="flex gap-2">
            <span
              :class="`text-sm text-orange-mango-600 bg-orange-mango-100 px-2 py-1 rounded-md ${currentExerciseType?.class}`">
              {{ currentExerciseType?.title }}
            </span>
            <span
              :class="`text-sm px-2 py-1 rounded-md ${currentExerciseDifficulty?.class}`">
              {{ currentExerciseDifficulty?.title }}
            </span>
            <span
              class="flex gap-2 items-center text-sm text-secondary-900 px-2 py-1">
              {{ blockComplexityType[currentExercise?.complexity ?? 'low'] }}
            </span>
            <span
              v-if="currentExercise && assessmentData"
              class="flex items-center text-sm">
              {{ getExerciseWeight(currentExercise) }}%
            </span>
          </div>
        </div>
        <div class="flex gap-2 items-center">
          <PVButton
            severity="neutral"
            class="bg-white text-sm flex gap-1.5 !px-2 !py-1.5 me-0 !border-0 ml-0.5"
            @click="openPreview">
            <Play :size="16" />
            תצוגה מקדימה
          </PVButton>
          <span :class="`text-sm px-2 py-1.5 rounded-md ${averageColor}`">
            <span v-if="reviewPer == 'student'">
              {{ isProject ? 'ציון הפרויקט:' : 'ציון השיעור:' }}
            </span>
            <span v-else>ממוצע כיתתי:</span>
            {{ average ?? '--' }}
          </span>
        </div>
      </div>

      <div
        class="h-[calc(100%-2.2rem)] p-3 flex flex-col gap-3 rounded-lg bg-white">
        <div class="flex items-center justify-between shrink-0">
          <div class="flex items-center h-full gap-2">
            <PVSelect
              v-model="selectedFilter"
              :options="filterBy"
              option-label="title"
              option-group-label="title"
              option-group-children="items"
              placeholder="כל התרגילים"
              :pt="filterByDropdown">
              <template #option="{ option }">
                <div class="flex align-items-center gap-2">
                  <div
                    class="border border-secondary-900 w-3.5 h-3.5 rounded-full flex items-center justify-center">
                    <div
                      class="w-2 h-2 rounded-full"
                      :class="{
                        'bg-secondary-900':
                          option.value === selectedFilter?.value,
                      }"></div>
                  </div>
                  <span>{{ option.title }}</span>
                </div>
              </template>
              <template #dropdownicon>
                <ChevronDown class="stroke-secondary-900" :size="16" />
              </template>
            </PVSelect>
            <PVButton
              v-if="
                exercisesForAIReview.length > 0 &&
                selectedFilter.value == 'needs_review'
              "
              v-tooltip.top="t('exercises_review.ai_feedback')"
              severity="secondary"
              :loading="aiLoading"
              class="h-full !px-2 !bg-white hover:!bg-secondary-100 active:!bg-secondary-200 ![border:0.8px_solid_#D7DFEA] disabled:opacity-50 disabled:cursor-not-allowed"
              @click="getAIFeedbacks">
              <span
                class="flex gap-1.5 items-center text-sm text-secondary-900">
                <template v-if="aiLoading">
                  <Loader :size="12" stroke-width="1.5" class="animate-spin" />
                </template>
                <template v-else>
                  <Wand2
                    :size="12"
                    stroke-width="1.5"
                    class="stroke-secondary-900"/>
                </template>
                AI
              </span>
            </PVButton>
          </div>
          <div
            v-if="reviewPer === 'exercise'"
            class="flex gap-1.5 items-center text-sm text-secondary-900">
            הצגת שמות תלמידים
            <PVInputSwitch v-model="showStudentsNames" />
          </div>
        </div>
        <div
          ref="exerciseContainer"
          class="h-full overflow-y-auto"
          @scroll="handleScroll">
          <div
            v-if="filterExercises?.length"
            class="flex-1 flex flex-col gap-3">
            <SingleExercise
              v-for="ex in visibleExercises"
              :key="`b-${ex.exercise?.id}-${ex.student?.id}`"
              :exercise="ex"
              :show-students-names="showStudentsNames"
              :is-project="isProject"
              :project-enrollments="props.projectEnrollments"
              :project-id="projectId"
              :stage-data="
                reviewPer === 'student'
                  ? stageData?.[ex.exercise?.id]
                  : stageData
              "></SingleExercise>
            <div v-if="isLoading" class="h-60 flex items-center justify-center">
              <ProgressSpinner />
            </div>
          </div>
          <div
            v-else
            class="flex-1 h-full flex flex-col gap-2 items-center justify-center">
            <img :src="NoExercises" alt="" />
            <p class="font-bold text-secondary-800">
              {{ noExerciseText }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>
<script setup lang="ts">
import { useStudentsStore } from '/@/app/store/students';
import PVButton from 'primevue/button';
import PVInputSwitch from 'primevue/inputswitch';
import {
  computed,
  watch,
  watchEffect,
  onMounted,
  onUnmounted,
  nextTick,
} from 'vue';
import PVSelect from 'primevue/select';
import { useRouteParams, useRouteQuery } from '@vueuse/router';
import { useCourseStore } from '/@/app/store/course';
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { Play, ChevronDown, Wand2, Loader } from 'lucide-vue-next';
import NoExercises from '/@/assets/no-exercises.svg?url';
import SingleExercise from '/@/views/components/LessonSubmission/SingleExercise.vue';
import {
  filterBy,
  blockDifficultyType,
  blockComplexityType,
  getScoreColor,
  getBlockSettingsType,
} from '/@/views/components/LessonSubmission/utils';
import { filterByDropdown } from '/@/views/components/LessonSubmission/styles';
import LoadingOverlay from '/@/App.vue';
import ProgressSpinner from 'primevue/progressspinner';
import { PropType } from 'vue';
import { IProjectEnrollment } from '/@/app/models/interfaces';
import { fetchPages } from '/@/app/services/courses';
import { useI18n } from 'vue-i18n';
import { keyBy, sumBy } from 'lodash-es';
import { useCourse } from '/@/views/CourseProgress/composables/useCourse';
import { useEventBus } from '@vueuse/core';

const courseStore = useCourseStore();
const studentsStore = useStudentsStore();
const submissions = computed(() => studentsStore.submissions);

const route = useRoute();
const router = useRouter();

const INITIAL_EXERCISES_TO_SHOW = 4;
const showStudentsNames = ref(true);
const props = defineProps({
  students: {
    type: Array,
    default: () => [],
  },
  isProject: {
    type: Boolean,
    default: false,
  },
  projectEnrollments: {
    type: Array as PropType<IProjectEnrollment[]>,
    default: () => [],
  },
  projectId: {
    type: String,
    default: '',
  },
});

const { t } = useI18n();

const reviewPer = useRouteQuery<any>('review_per', 'exercise');
const student = useRouteQuery<any>('student');
const exercise = useRouteQuery<any>('exercise');
const lesson = useRouteParams('lesson');

const allAIFeedbacksMode = ref(false);

const { navigateToPreviewProject } = useCourse(
  route.params.group as string,
  route.params.course as string,
);

const isProject = computed(() => props.isProject);
const projectId = computed(() => props.projectId);
const initialExercises = computed(() =>
  props.isProject
    ? courseStore.exercises
    : courseStore.exercises.filter(item => item.questions.length),
);

const selectedFilter = ref({ title: 'נותרו לבדיקה', value: 'needs_review' });
const currentExercise = computed(() =>
  initialExercises.value.find(ex => ex.id === exercise.value),
);
const currentExerciseSettings = computed(
  () => currentExercise.value?.questions[0]?.settings,
);

const currentExerciseType = computed(() =>
  getBlockSettingsType(currentExerciseSettings.value?.type),
);
const currentExerciseDifficulty = computed(
  () => blockDifficultyType[currentExercise.value?.difficulty ?? 'easy'],
);
const assessmentData = computed(() => {
  if (!courseStore.course || !initialExercises.value) return;

  const initialData = Object.fromEntries(
    ['low', 'high'].flatMap(complexity =>
      ['manual', 'automatic'].map(type => [
        `${complexity}_${type}`,
        { weight: 0, exercises: 0 },
      ]),
    ),
  );
  courseStore.course.settings?.assessmentProfile?.categories.forEach(cat => {
    const key = `${cat.complexity}_${cat.type}`;
    if (initialData[key]) {
      initialData[key].weight = cat.weight;
    }
  });

  initialExercises.value.forEach(ex => {
    const key = `${ex?.complexity ?? 'low'}_${
      ex.settings?.needs_review ? 'manual' : 'automatic'
    }`;
    const submitByGroup =
      ex?.questions.some(question => question?.settings?.submitByGroup) ??
      false;
    if (initialData[key] && !submitByGroup && ex?.completion?.gradable) {
      initialData[key].exercises++;
    }
  });
  const weightSumForZeroExercises = Object.values(initialData)
    .filter(item => item.exercises === 0)
    .reduce((sum, item) => sum + item.weight, 0);

  const weightSumForNonZeroExercises = Object.values(initialData)
    .filter(item => item.exercises > 0)
    .reduce((sum, item) => sum + item.weight, 0);

  const factor =
    weightSumForNonZeroExercises > 0
      ? weightSumForZeroExercises / weightSumForNonZeroExercises
      : 0;

  Object.keys(initialData).forEach(key => {
    if (initialData[key].exercises != 0) {
      initialData[key].weight += initialData[key].weight * factor;
    } else {
      initialData[key].weight = 0;
    }
  });

  return initialData;
});
const getExerciseWeight = exercise => {
  const key = `${exercise?.complexity ?? 'low'}_${exercise?.settings?.needs_review ? 'manual' : 'automatic'}`;
  const submitByGroup =
    exercise?.questions.some(question => question?.settings?.submitByGroup) ??
    false;

  if (
    submitByGroup ||
    !exercise?.completion?.gradable ||
    exercise?.questions[0]?.name == 'decision_making_game'
  ) {
    return 0;
  }
  return Math.round(
    assessmentData.value[key]?.weight / assessmentData.value[key]?.exercises,
  );
};
const aiReviewBus = useEventBus('getAIreview');
const aiFeedbackFinishedBus = useEventBus('finishedAIFeedback');

const aiLoading = ref(false);
const aiReviewCount = ref(0);

const exercisesForAIReview = computed(() => {
  return (
    filterExercises.value?.filter(ex => {
      const submission = submissions.value?.find(
        s =>
          s.exercise === ex.exercise.id &&
          s.participants.includes(ex.student.id),
      );

      return (
        ex.exercise.questions?.length &&
        !isProject.value &&
        ex.exercise.questions.every(
          question => question.name === 'free_text' || question.name === 'textarea',
        ) &&
        (submission?.status === 'awaiting_review' ||
          submission?.status === 'changes_requested')
      );
    }) ?? []
  );
});

const getAIFeedbacks = () => {
  allAIFeedbacksMode.value = true;
  if (exercisesForAIReview.value.length === 0) return;

  // Clear all submission-related items from localStorage
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key?.startsWith('submission_')) {
      localStorage.removeItem(key);
    }
  }

  aiLoading.value = true;
  setTimeout(() => {
    aiLoading.value = false;
  }, 3000);
  aiReviewCount.value = 0;
  aiReviewBus.emit();
};

aiFeedbackFinishedBus.on(() => {
  aiReviewCount.value++;
  if (aiReviewCount.value === exercisesForAIReview.value.length) {
    aiLoading.value = false;
    aiReviewCount.value = 0;
  }
});

const average = computed(() => {
  if (props.isProject) {
    if (reviewPer.value === 'exercise') {
      const totalScore = sumBy(props.projectEnrollments, 'total_score') ?? 0;
      return props.projectEnrollments.length
        ? Math.round(totalScore / props.projectEnrollments.length)
        : null;
    }
    if (reviewPer.value === 'student') {
      const studentEnrollment = props.projectEnrollments.find(
        enrollment => enrollment.student === student.value,
      );
      return studentEnrollment?.total_score
        ? Math.round(studentEnrollment.total_score)
        : null;
    }
  }
  if (reviewPer.value == 'student') {
    return (
      studentsStore.enrollments
        .find(en => en.student.id === student.value)
        ?.summary.find(s => s.lesson.id == lesson.value && s.progress == 100)
        ?.score ?? null
    );
  } else {
    const filteredSubmissions = submissions.value.filter(
      s =>
        s.exercise === exercise.value &&
        s.type !== 'collaboration' &&
        !exercise.value.questions?.some(q => q.name === 'decision_making_game')
          .length,
    );
    const scores = filteredSubmissions.map(s => s.score);

    const averageScore = scores.length
      ? Math.round(
          scores.reduce((total, score) => total + score, 0) / scores.length,
        )
      : null;
    return averageScore;
  }
});
const averageColor = computed(() => getScoreColor(average.value, false));

const studentsById = computed(() => keyBy(props.students, 'id'));

const exercises = computed(() => {
  if (props.isProject) {
    if (reviewPer.value === 'exercise') {
      const result = props.projectEnrollments?.map(({ student, summary }) => ({
        exercise: {
          id: exercise.value,
          status: summary.find(({ stage }) => stage.id === exercise.value)
            ?.status,
        },
        student: studentsById.value[student],
      }));
      return result;
    } else {
      const studentEnrollment = props.projectEnrollments?.find(
        enrollment => enrollment.student === student.value,
      );
      const result = studentEnrollment?.summary?.map(summary => {
        return {
          exercise: { id: summary.stage.id, status: summary.status },
          student: { ...studentsById.value[student.value] },
        };
      });
      return result;
    }
  }
  if (!initialExercises.value) return null;
  if (reviewPer.value == 'student') {
    const studentObj = props.students.find(s => s.id === student.value);
    return initialExercises.value.map(ex => {
      return {
        exercise: {
          ...ex,
          weight: getExerciseWeight(ex),
        },
        student: {
          ...studentObj,
        },
      };
    });
  } else {
    const matchedExercise = initialExercises.value.find(
      ex => ex.id === exercise.value,
    );
    if (!matchedExercise) return [];
    const result = props.students.flatMap(currentStudent => ({
      exercise: { ...matchedExercise },
      student: {
        ...currentStudent,
      },
    }));
    return result;
  }
});

const filterExercises = computed(() => {
  if (!exercises.value) return null;
  if (selectedFilter.value.value == 'all') return exercises.value;
  if (selectedFilter.value.value == 'finished') {
    return exercises.value.filter(b => {
      if (isProject.value) {
        return b.exercise.status === 'finished';
      } else {
        const sumb = submissions.value.find(
          s =>
            s.exercise == b.exercise.id &&
            s.participants.includes(b.student.id),
        );
        return sumb?.type === 'collaboration'
          ? sumb?.records.some(r => r.userId == b.student.id)
          : sumb?.status === 'finished';
      }
    });
  } else {
    return exercises.value.filter(b => {
      if (isProject.value) {
        return b.exercise.status === 'awaiting_review';
      } else {
        const sumb = submissions.value.find(
          s =>
            s.exercise == b.exercise.id &&
            s.participants.includes(b.student.id),
        );
        return sumb?.type === 'collaboration'
          ? !sumb?.records.some(r => r.userId == b.student.id) &&
              sumb?.status != 'pending'
          : sumb?.status != 'finished' && sumb;
      }
    });
  }
});

const openPreview = () => {
  if (isProject.value) {
    navigateToPreviewProject(
      reviewPer.value === 'exercise'
        ? (exercise.value as string)
        : projectStages.value?.[0]?.stage?.id,
    );
    return;
  }

  const query = {
    ...(reviewPer.value === 'exercise' &&
    currentExercise.value.questions[0]?.page
      ? { page: currentExercise.value.questions[0].page }
      : {}),
  };
  const routeData = router.resolve({
    name: 'preview',
    params: {
      course: route.params.course,
      lesson: route.params.lesson,
    },
    query,
  });

  window.open(routeData.href, '_blank');
};

const exerciseContainer = ref(null);

const exercisesToShow = ref(INITIAL_EXERCISES_TO_SHOW);

const isLoading = ref(false);

const loadMoreExercises = () => {
  if (
    isLoading.value ||
    visibleExercises.value?.length === filterExercises.value?.length
  )
    return;

  isLoading.value = true;
  exercisesToShow.value += 3;

  setTimeout(() => {
    isLoading.value = false;
  }, 600);
};

const visibleExercises = computed(() => {
  return filterExercises.value?.slice(0, exercisesToShow.value);
});

const handleScroll = e => {
  if (
    exerciseContainer.value?.scrollTop +
      exerciseContainer.value?.clientHeight >=
    exerciseContainer.value?.scrollHeight - 50
  ) {
    loadMoreExercises();
  }
};

const checkNewExercisesForAIReview = () => {
  if (!allAIFeedbacksMode.value) return;
  nextTick(() => {
    aiReviewBus.emit();
  });
};

watch(
  () => visibleExercises.value?.length,
  newLength => {
    if (newLength > 0) {
      checkNewExercisesForAIReview();
    }
  },
);

watch([student, exercise], () => {
  exercisesToShow.value = INITIAL_EXERCISES_TO_SHOW;
});

const noExerciseText = computed(() => {
  switch (selectedFilter.value.value) {
    case 'finished':
      return t('exercises_review.no_finished');
    case 'needs_review':
      return t('exercises_review.no_needs_review');
    default:
      return t('exercises_review.no_exercises');
  }
});

const stageData = ref();

const isStageValid = () => {
  return props.projectEnrollments?.find(enrollment =>
    enrollment.summary?.find(summary => summary.stage.id === exercise.value),
  );
};

const loadPages = (stageId: string) => {
  const { onSuccess } = fetchPages(props.projectId, stageId);
  onSuccess(({ data }) => {
    // If reviewing per student, we need to store data for multiple stages
    if (reviewPer.value === 'student') {
      stageData.value = {
        ...(stageData.value || {}),
        [stageId]: data,
      };
    } else {
      // If reviewing per exercise, we just store the current stage data
      stageData.value = data;
    }
  });
};

const projectStages = computed(() => {
  return props.projectEnrollments?.find(
    enrollment => enrollment.student === student.value,
  )?.summary;
});

watchEffect(() => {
  if (props.projectId && isProject.value) {
    if (reviewPer.value === 'student' && student.value) {
      // Load all stages for this student
      projectStages.value?.forEach(summary => {
        loadPages(summary.stage.id);
      });
    } else if (
      reviewPer.value === 'exercise' &&
      exercise.value &&
      isStageValid()
    ) {
      // Load only the current stage
      loadPages(exercise.value);
    }
  }
});

const clearAIFeedbackFromStorage = () => {
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key?.startsWith('submission_')) {
      localStorage.removeItem(key);
    }
  }
};

onMounted(() => {
  clearAIFeedbackFromStorage();
});

if (typeof window !== 'undefined') {
  window.addEventListener('beforeunload', clearAIFeedbackFromStorage);
}

onUnmounted(() => {
  if (typeof window !== 'undefined') {
    window.removeEventListener('beforeunload', clearAIFeedbackFromStorage);
  }
});

watch(
  () => route.fullPath,
  () => {
    allAIFeedbacksMode.value = false;
  },
);

watch(
  () => selectedFilter.value.value,
  () => {
    allAIFeedbacksMode.value = false;
  },
);
</script>
