<template>
  <PVTabs v-model:value="activeIndex">
    <PVTabList :pt="tabViewPT.tablist">
      <PVTab :pt="tabViewPT.tab" :value="'exercise'">
        {{ isProject ? 'שלבים' : 'תרגילים' }}
      </PVTab>
      <PVTab :pt="tabViewPT.tab" :value="'student'">{{ 'תלמידים' }}</PVTab>
    </PVTabList>
    <PVTabPanels :pt="tabViewPT.tabpanels">
      <PVTabPanel class="flex-1 h-full flex flex-col" :value="'exercise'">
        <transition name="slide">
          <div
            v-if="!courseStore.loading"
            :key="activeIndex"
            class="flex-1 h-full flex flex-col">
            <PVInputGroup class="mb-5">
              <PVInputText
                v-model="searchExercise"
                :placeholder="isProject ? 'חיפוש שלב' : 'חיפוש תרגיל'"
                class="outline-none ring-0"/>
              <PVInputGroupAddon>
                <Search class="stroke-secondary-600" stroke-width="1.2" />
              </PVInputGroupAddon>
            </PVInputGroup>
            <div
              v-if="filteredExercises.length"
              class="h-[calc(100vh-280px)] overflow-y-auto">
              <ExerciseCard
                v-for="exercise in filteredExercises"
                :key="exercise.id"
                :exercise="exercise"
                :selected="exercise.id === activeExercise"
                @click="activeExercise = exercise.id"/>
            </div>
            <div v-else class="flex flex-col items-center gap-2 mt-5">
              <img :src="SearchNoResult" alt="no results" />
              <p>לא נמצאו תוצאות חיפוש</p>
            </div>
          </div>
        </transition>
      </PVTabPanel>
      <PVTabPanel :value="'student'">
        <transition name="slide">
          <div :key="activePanel" class="flex-1 h-full flex flex-col">
            <PVInputGroup class="mb-5">
              <PVInputText
                v-model="searchStudent"
                placeholder="חיפוש תלמיד"
                :disabled="!students.length"/>
              <PVInputGroupAddon>
                <Search class="stroke-secondary-600" stroke-width="1.2" />
              </PVInputGroupAddon>
            </PVInputGroup>
            <div
              v-if="filteredStudents.length"
              class="h-[calc(100vh-280px)] overflow-y-auto">
              <StudentCard
                v-for="student in filteredStudents"
                :key="student.id"
                :data-student-id="student.id"
                :student="student"
                :selected="student.id === activeStudent"
                @click="activeStudent = student.id"/>
            </div>
            <div v-else class="flex flex-col items-center gap-2 mt-5">
              <img :src="SearchNoResult" alt="no results" />
              <p>לא נמצאו תוצאות חיפוש</p>
            </div>
          </div>
        </transition>
      </PVTabPanel>
    </PVTabPanels>
  </PVTabs>
</template>
<script setup lang="ts">
import PVTabs from 'primevue/tabs';
import PVTabPanels from 'primevue/tabpanels';
import PVTabPanel from 'primevue/tabpanel';
import PVTabList from 'primevue/tablist';
import PVTab from 'primevue/tab';
import PVInputText from 'primevue/inputtext';
import PVInputGroup from 'primevue/inputgroup';
import PVInputGroupAddon from 'primevue/inputgroupaddon';
import SearchNoResult from '/@/assets/search-no-result.png';
import { Search } from 'lucide-vue-next';
import { computed, PropType, ref, watch, nextTick } from 'vue';
import StudentCard from '/@/views/components/LessonSubmission/StudentCard.vue';
import ExerciseCard from '/@/views/components/LessonSubmission/ExerciseCard.vue';
import { getExerciseTitle } from '/@/views/components/LessonSubmission/utils';
import { useCourseStore } from '/@/app/store/course';
import {
  IProjectEnrollment,
  IProjectEnrollmentStage,
} from '/@/app/models/interfaces';

const courseStore = useCourseStore();
const props = defineProps({
  exercises: {
    type: Array,
    default: () => [],
  },
  projectStages: {
    type: Array as PropType<IProjectEnrollmentStage[]>,
    default: () => [],
  },
  projectEnrollments: {
    type: Array as PropType<IProjectEnrollment[]>,
    default: () => [],
  },
  students: {
    type: Array,
    default: () => [],
  },
  isProject: {
    type: Boolean,
    default: false,
  },
});

const activeIndex = defineModel('activePanel');
const activeStudent = defineModel('activeStudent');
const activeExercise = defineModel('activeExercise');

const searchExercise = ref('');
const searchStudent = ref('');
const filteredStudents = computed(() => {
  if (!searchStudent.value) return props.students;
  const query = searchStudent.value.toLowerCase();

  return props.students.filter(item =>
    item.fullName.toLowerCase().includes(query),
  );
});

const filteredExercises = computed(() => {
  const items = props.isProject
    ? props.projectStages?.map(stage => ({
        ...stage,
        title: stage.stage.title,
        id: stage.stage.id,
        studentsToReviewCount: props.projectEnrollments
          ?.map(enrollment => enrollment.summary)
          .flat()
          .filter(
            summary =>
              summary.stage.id === stage.stage.id &&
              summary.status === 'awaiting_review',
          )?.length,
      }))
    : props.exercises;
  if (!searchExercise.value)
    return props.isProject
      ? items
      : items.filter(item => item.questions.length);
  const query = searchExercise.value.toLowerCase();
  return items.filter(item => {
    const displayName = props.isProject ? item.title : getExerciseTitle(item);
    return (
      displayName &&
      displayName.toLowerCase().includes(query) &&
      (item.questions.length || props.isProject)
    );
  });
});

const tabViewPT = {
  tablist: {
    tablist: { class: 'flex mx-3 pt-5 h-full flex-1 ' },
    activebar: { class: ['hidden'] },
  },

  tabpanels: { root: { class: 'px-3 h-full' } },
  tab: {
    root: ({ parent, context }) => ({
      class: [
        'h-full relative',
        'flex items-center justify-center',
        'w-full',
        'py-1.5 px-[1.125rem]',
        '-mb-1',
        'border-b-1',
        'font-simplerRegular text-md font-light',
        {
          'border-surface-200': !context.active,

          'text-secondary-450': !context.active,

          'border-secondary-900': context.active,
          'text-secondary-900': context.active,
        },
        'transition-all duration-200',
        'cursor-pointer select-none text-decoration-none',
        'overflow-hidden',
        'user-select-none',
      ],
    }),
  },
};

// Add watch effect for activeStudent
watch(
  [activeStudent, filteredStudents],
  ([newStudentId, newFilteredStudents]) => {
    if (newStudentId && newFilteredStudents.length) {
      nextTick(() => {
        const selectedCard = document.querySelector(
          `[data-student-id="${newStudentId}"]`,
        );
        selectedCard?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      });
    }
  },
  { immediate: true },
);
</script>
