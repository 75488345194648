<template>
  <div class="flex flex-col gap-2">
    <div
      class="rounded-md border border-secondary-250 p-5 bg-white flex flex-col gap-2">
      <div class="flex items-center justify-between">
        <div
          v-if="reviewPer == 'student'"
          class="flex items-center justify-between">
          <div
            v-if="exercise.exercise?.questions?.length"
            class="flex gap-2 h-full">
            <span
              :class="`text-sm text-orange-mango-600 bg-orange-mango-100 px-2 py-1 rounded-md ${currentExerciseType?.class}`">
              {{ currentExerciseType?.title }}
            </span>
            <span
              :class="`text-sm px-2 py-1 rounded-md ${currentExerciseDifficulty?.class}`">
              {{ currentExerciseDifficulty?.title }}
            </span>
            <span
              class="flex gap-2 items-center text-sm text-secondary-900 py-1">
              {{ blockComplexityType[exercise.exercise?.complexity ?? 'low'] }}
            </span>
            <span class="flex items-center text-sm">
              {{ exercise.exercise.weight }}%
            </span>
            <span
              v-if="!studentCollaborationRecord?.length || changesRequested"
              class="text-sm flex items-center gap-1.5">
              /
              <span
                v-if="!studentCollaborationRecord?.length"
                class="flex items-center font-bold text-red-600 text-sm">
                לא הוגש
              </span>
              <span
                v-else
                class="flex items-center font-bold text-red-600 text-sm">
                נשלח לתיקון
              </span>
            </span>
          </div>
          <div v-if="isProject" class="flex items-center gap-1.5">
            <span class="text-sm">
              {{ stageData?.title }}
            </span>
            <span class="text-sm flex items-center gap-1.5">
              /
              <span
                v-if="exercise.exercise.status === 'awaiting_review'"
                class="flex items-center font-bold text-red-600 text-sm">
                ממתין לבדיקה
              </span>
              <span
                v-else-if="exercise.exercise.status === 'in_progress'"
                class="flex items-center font-bold text-red-600 text-sm">
                לא הוגש
              </span>
              <span
                v-else-if="exercise.exercise.status === 'finished'"
                class="flex items-center font-bold text-green-600 text-sm">
                נבדק
              </span>
              <span
                v-else
                class="flex items-center font-bold text-red-600 text-sm">
                נשלח לתיקון
              </span>
            </span>
          </div>
        </div>
        <div v-else>
          <div class="flex gap-2 items-center h-full">
            <img
              :src="
                showStudentsNames && exercise.student?.avatar
                  ? exercise.student?.avatar
                  : defaultImage
              "
              alt="avatar"
              class="rounded-full w-6 h-6"/>
            <p class="font-bold text-secondary-900">
              {{ showStudentsNames ? exercise.student?.fullName : 'אנונימי' }}
              <span
                v-if="!studentCollaborationRecord?.length || changesRequested"
                class="font-bold">
                /
                <span
                  v-if="!studentCollaborationRecord?.length"
                  class="font-bold text-red-600">
                  לא הוגש
                </span>
                <span v-else class="font-bold text-red-600">נשלח לתיקון</span>
              </span>
            </p>
          </div>
        </div>
        <div class="flex items-center gap-1">
          <PVSelect
            v-if="!exerciseSettings?.submitByGroup && !isProject"
            v-model="selectedSubmission"
            :options="historyOptions"
            :disabled="!studentSubmission?.history?.length"
            option-label="title"
            :pt="dropdownPT">
            <template #value>
              <div v-if="selectedSubmission">
                {{ selectedSubmission.title }}
              </div>
              <div v-else class="flex justify-center items-center gap-2">
                <File />
                <span class="text-sm text-secondary-900">
                  {{
                    studentSubmission?.history?.length +
                      (studentSubmission?.records?.length ? 1 : 0) || 0
                  }}
                  הגשות
                </span>
              </div>
            </template>
            <template #dropdownicon>
              <ChevronDown
                v-if="!selectedSubmission"
                class="stroke-secondary-900"
                :size="16"/>
              <X
                v-else
                :size="16"
                class="cursor-pointer"
                @click="clearSelectedSubmission"/>
            </template>
          </PVSelect>
          <button
            v-if="showScoreField && !isProject"
            :disabled="changesRequested || selectedSubmission"
            class="flex items-center border h-6 border-secondary-300 rounded px-2 text-sm text-secondary-900"
            :class="{
              'opacity-50': changesRequested || selectedSubmission,
            }"
            @click="showConfirmDialog = true">
            שליחה לתיקון
          </button>
        </div>
      </div>
      <div v-if="!isProject">
        <BlockPreview
          v-for="question in updatedQuestionList"
          :key="`s-${exercise.student.id}-${question.id}`"
          :block="question"/>
      </div>
      <PageContent v-else />
      <div
        :dir="courseLocale !== 'he' ? 'ltr' : 'rtl'"
        class="flex w-full gap-2 justify-end">
        <div v-show="!selectedSubmission" class="flex w-full">
          <div
            v-if="
              (showScoreField && isEditingFeedback) ||
              (!studentSubmission?.review_notes &&
                !isEditingFeedback &&
                showScoreField)
            "
            class="flex items-center rounded bg-white w-full min-h-9">
            <button
              v-if="isEditingFeedback"
              v-tooltip.top="t('exercises_review.close')"
              class="border border-red-400 bg-red-50 h-full px-2 hover:bg-red-100 disabled:opacity-50 transition-colors"
              :class="{
                'cursor-not-allowed': aiFeedbackLoading,
                'rounded-r border-l': courseLocale === 'he',
                'rounded-l border-r': courseLocale !== 'he',
              }"
              :disabled="aiFeedbackLoading"
              @click="isEditingFeedback = false">
              <X class="w-[14px] h-[14px] stroke-red-400" />
            </button>
            <button
              v-if="isAiableExercise"
              v-tooltip.top="t('exercises_review.ai_feedback')"
              class="border border-secondary-300 bg-secondary-100 px-3 h-full hover:bg-secondary-200 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
              :disabled="loading || aiFeedbackLoading"
              @click="clearAndGetAiFeedback()">
              <Wand2 class="w-[14px] h-[14px] text-secondary-900" />
            </button>
            <div
              class="flex h-full items-center border border-secondary-300 w-full focus-within:border-y-primary"
              :class="{
                'focus-within:border-l-primary rounded-l':
                  courseLocale !== 'he' &&
                  !isEditingFeedback &&
                  !(
                    questions &&
                    !isProject &&
                    questions.every(question => question.name === 'free_text')
                  ),
                'rounded-r focus-within:border-r-primary':
                  courseLocale === 'he' &&
                  !isEditingFeedback &&
                  !(
                    questions &&
                    !isProject &&
                    questions.every(question => question.name === 'free_text')
                  ),
              }">
              <div class="flex-1 w-full p-2">
                <PVSkeleton v-if="aiFeedbackLoading" class="h-[64px]" />
                <PVTextArea
                  v-else
                  ref="feedbackTextarea"
                  v-model="feedback"
                  :rows="1"
                  style="
                    resize: none;
                    min-height: 22px;
                    max-height: 74px;
                    overflow-y: auto;
                  "
                  :placeholder="
                    courseLocale !== 'he' ? 'Feedback' : 'משוב לתלמיד'
                  "
                  auto-resize
                  class="w-full flex items-center"
                  :pt="{
                    root: 'flex-1 border-0 focus:outline-none focus:ring-0 resize-none hover:resize transition px-2',
                  }"
                  @focus="
                    () => {
                      textAreaFocus = true;
                      aiError = false;
                    }
                  "/>
              </div>

              <div class="relative flex items-center justify-center">
                <button
                  v-tooltip.top="t('exercises_review.emoji')"
                  class="px-2 transition-colors"
                  :class="{
                    'cursor-not-allowed': aiFeedbackLoading,
                  }"
                  :disabled="aiFeedbackLoading"
                  @click="emojiMenu.toggle($event)">
                  <Smile
                    class="w-4 h-4 text-secondary-900"
                    :class="{ 'fill-yellow-500': showEmojiPicker }"/>
                </button>
                <PVMenu
                  ref="emojiMenu"
                  :model="emojiMenuItems"
                  popup
                  :pt="{
                    root: 'p-0',
                  }"
                  :pt-options="{ mergeSections: false, mergeProps: true }"
                  @show="showEmojiPicker = true"
                  @hide="showEmojiPicker = false">
                  <template #item>
                    <div v-ripple class="flex items-center gap-2">
                      <EmojiPicker
                        ref="emojiPickerRef"
                        class="emoji-picker"
                        :style="{ width: '320px' }"
                        :native="true"
                        @select="onEmojiSelect"/>
                    </div>
                  </template>
                </PVMenu>
              </div>
            </div>

            <button
              v-tooltip.top="t('exercises_review.send')"
              class="border border-secondary-500 bg-secondary-100 h-full px-2 hover:bg-secondary-200 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
              :class="{
                'rounded-r ': courseLocale !== 'he',
                'rounded-l ': courseLocale === 'he',
                '!border-primary': textAreaFocus,
              }"
              :disabled="
                loading || !feedback?.trim().length || aiFeedbackLoading
              "
              :loading="loading"
              @click="sendFeedback">
              <component
                :is="courseLocale !== 'he' ? ArrowRight : ArrowLeft"
                class="w-4 h-4"
                :class="{
                  'stroke-primary': textAreaFocus,
                }"/>
            </button>
          </div>

          <PVButton
            v-if="false"
            severity="neutral"
            class="bg-white text-sm flex gap-1.5 !px-3 !py-1.5 me-0 !border-0 pointer-events-none opacity-50">
            <ChatMessage />
            ערעור
          </PVButton>
        </div>

        <div class="flex gap-2 justify-end">
          <Transition name="fade">
            <div
              v-if="messageVisible"
              class="flex items-center justify-center transition-all">
              <Info class="fill-orange-500 stroke-white" />
              <p class="text-sm text-orange-500">שים לב! הוספת ציון בונוס</p>
            </div>
          </Transition>
          <div
            v-if="showScoreField"
            :class="[
              `flex gap-2 items-center justify-center px-2.5 py-1 border rounded-md ${scoreColor}`,
              {
                '!border-primary !bg-white !text-secondary-900': scoreFocus,
              },
            ]">
            <span class="text-sm !leading-0 text-inherit">
              {{ courseLocale !== 'he' ? 'Grade:' : 'ציון:' }}
            </span>
            <PVInputNumber
              v-model="score"
              :disabled="!!selectedSubmission || aiFeedbackLoading"
              :min="0"
              :max="120"
              placeholder="--"
              :pt="inputNumberPT"
              @focus="scoreFocus = true"
              @blur="updateScore"/>
          </div>
        </div>
      </div>
      <div v-if="isProject">
        <p class="flex items-start gap-2 text-sm text-[#F0920F]">
          <TriangleAlert :size="12" class="mt-[3px]" />
          {{ t('exercises_review.attention_message') }}
        </p>
      </div>
      <div
        v-if="currentReviewNotes?.review_notes"
        class="mt-4 flex flex-col gap-4"
        :class="{ 'opacity-50 pointer-events-none': isEditingFeedback }">
        <Divider />
        <div class="flex items-center justify-between w-full">
          <div class="flex items-center gap-1">
            <div
              class="flex items-center justify-center rounded-full bg-red-100 w-6 h-6">
              <span class="text-xs text-red-900">
                {{ accountStore.user.first_name.slice(0, 1) }}
              </span>
              <span class="text-xs text-red-900">
                {{ accountStore.user.last_name.slice(0, 1) }}
              </span>
            </div>
            <span class="text-xs text-secondary-900">
              {{ accountStore.user.fullName }}
            </span>
            <span
              v-if="currentReviewNotes.reviewed_on"
              class="text-xxs text-secondary-700">
              {{ `לפני ${timeAgo(currentReviewNotes.reviewed_on)}` }}
            </span>
          </div>
          <div>
            <button
              v-if="!selectedSubmission"
              v-tooltip.top="t('exercises_review.more_actions')"
              class="p-1.5 rounded hover:bg-[#EAEEF6]"
              @click="e => toggleMenu(e)">
              <EllipsisVertical :size="13" />
            </button>
            <PVMenu ref="menu" :model="menuItems" popup>
              <template #item="{ item, props }">
                <div
                  v-ripple
                  class="flex items-center gap-2"
                  v-bind="props.action">
                  <component :is="item.icon" class="w-4 h-4" />
                  <span>{{ item.label }}</span>
                </div>
              </template>
            </PVMenu>
          </div>
        </div>
        <div
          :dir="courseLocale !== 'he' ? 'ltr' : 'rtl'"
          class="text-sm text-secondary-900 max-h-[150px] overflow-y-auto break-words">
          {{ currentReviewNotes.review_notes }}
        </div>
      </div>
      <div
        v-if="aiError"
        class="text-sm text-red-500 flex items-center justify-center">
        {{ t('exercises_review.ai_feedback_error') }}
      </div>
    </div>
    <PVDialog
      v-model:visible="showConfirmDialog"
      :modal="true"
      :draggable="false"
      :header="t('exercises_review.send_for_correction')"
      :style="{ width: '350px' }">
      <div class="text-secondary-900">
        {{ t('exercises_review.confirm_send') }}
      </div>
      <template #footer>
        <div class="flex justify-end gap-2">
          <PVButton
            severity="primary"
            class="bg-primary text-white"
            @click="confirmSendForChanges">
            {{ t('exercises_review.send') }}
          </PVButton>
        </div>
      </template>
    </PVDialog>
    <PVDialog
      v-model:visible="showSuccessDialog"
      :modal="true"
      :draggable="false"
      :show-header="false"
      :closable="false"
      :style="{ width: '350px' }"
      class="text-center">
      <div class="flex flex-col items-center">
        <div class="flex justify-end w-full">
          <button
            class="p-1 hover:bg-secondary-100 rounded transition-colors"
            @click="showSuccessDialog = false">
            <X class="w-4 h-4 text-secondary-600" />
          </button>
        </div>
        <div class="flex flex-col items-center gap-4 p-3">
          <img :src="successIcon" alt="success" class="w-32" />
          <div class="text-secondary-900 font-simplerBold px-6">
            {{ t('exercises_review.success_message') }}
          </div>
        </div>
      </div>
    </PVDialog>
  </div>
</template>

<script setup lang="ts">
import { renderBlock } from '@';
import { computed, h, nextTick, onUnmounted, PropType, ref, watch } from 'vue';
import { useStudentsStore } from '/@/app/store/students';
import PVInputNumber from 'primevue/inputnumber';
import { useStateMapper } from '/@/app/composables/useStateMapper';
import PVButton from 'primevue/button';
import ChatMessage from '/@/assets/chat-message.svg';
import {
  ChevronDown,
  Info,
  X,
  Wand2,
  ArrowLeft,
  ArrowRight,
  EllipsisVertical,
  Smile,
  Pencil,
  TriangleAlert,
} from 'lucide-vue-next';
import {
  blockComplexityType,
  blockDifficultyType,
  numbersMapping,
  getScoreColor,
  getBlockSettingsType,
} from '/@/views/components/LessonSubmission/utils';
import { reviewSubmission, reviewFeedbackAI } from '/@/app/services/students';
const { toState } = useStateMapper();
import PVSelect from 'primevue/select';
import File from '/@/assets/file.svg';
import { useRouteQuery } from '@vueuse/router/index';
import { setSubmissionScore } from '/@/app/services/students';
import { useRoute } from 'vue-router';
import { useCourseStore } from '/@/app/store/course';
import PVMenu from 'primevue/menu';
import {
  historyDropdownPT,
  inputNumberPT,
} from '/@/views/components/LessonSubmission/styles';
import { get, isEmpty } from 'lodash';
import PVTextArea from 'primevue/textarea';
import { useAccountStore } from '/@/app/store/account';
import { useProjectSubmission } from './composables/ProjectSubmission';
import { useProjectFeedback } from './composables/ProjectFeedback';
import { IProjectEnrollment } from '/@/app/models/interfaces';
import Divider from 'primevue/divider';
import { timeAgo } from '@/utils';
import { setProjectScore } from '/@/app/services/projects';
import { useProject } from '../../CourseProgress/composables/useProject';
import PVDialog from 'primevue/dialog';
import EmojiPicker from 'vue3-emoji-picker';
import 'vue3-emoji-picker/css';
import successIcon from '/@/assets/success.png';
import PVSkeleton from 'primevue/skeleton';
import { useI18n } from 'vue-i18n';
import { useToast } from 'primevue/usetoast';
import { useEventBus } from '@vueuse/core';

interface AIFeedbackResponse {
  request_id: string;
  score: number;
  feedback: string;
}
const reviewPer = useRouteQuery<any>('review_per', 'exercise');
const props = defineProps({
  exercise: {
    type: Object,
    default: () => ({}),
  },
  showStudentsNames: {
    type: Boolean,
    default: true,
  },
  isProject: {
    type: Boolean,
    default: false,
  },
  projectId: {
    type: String,
    default: '',
  },
  projectEnrollments: {
    type: Array as PropType<IProjectEnrollment[]>,
    default: () => [],
  },
  stageData: {
    type: Object,
    default: () => ({}),
  },
});

const defaultImage =
  'https://res.cloudinary.com/dcodkxpej/image/upload/v1725192676/default-avatar-image_adpnhe.png';

const studentsStore = useStudentsStore();
const courseStore = useCourseStore();
const route = useRoute();
const submissions = computed(() => studentsStore.submissions);
const accountStore = useAccountStore();
const isEditingFeedback = ref(false);
const menu = ref();
const toast = useToast();
const showConfirmDialog = ref(false);
const textAreaFocus = ref(false);
const feedbackTextarea = ref(null);
const showEmojiPicker = ref(false);
const showSuccessDialog = ref(false);
const emojiPickerRef = ref();
const { t } = useI18n();
const dropdownPT = computed(() =>
  historyDropdownPT(!studentSubmission.value?.history?.length),
);

const lessonId = route.params.lesson;

watch(isEditingFeedback, newValue => {
  if (newValue) {
    nextTick(() => {
      const textarea = feedbackTextarea.value?.$el;
      if (textarea) {
        textarea.focus();
      }
    });
  }
});

const emojiMenu = ref();

const onEmojiSelect = emoji => {
  feedback.value = (feedback.value || '') + emoji.i;
  emojiMenu.value?.hide();
};

const menuItems = ref([
  {
    label: t('exercises_review.edit'),
    icon: Pencil,
    command: () => {
      isEditingFeedback.value = true;
      feedback.value = studentSubmission.value?.review_notes;
      score.value = studentSubmission.value?.score;
    },
  },
]);

const toggleMenu = event => {
  menu.value.toggle(event);
};

const projectId = computed(() => props.projectId);
const projectEnrollments = computed(() => props.projectEnrollments);
const isProject = computed(() => props.isProject);
const student = computed(() => props.exercise.student?.id);
const stage = computed(() => props.exercise.exercise?.id);
const stageData = computed(() => props.stageData);
const courseLocale = computed(() => courseStore.course.locale);
const aiError = ref(false);
const aiFeedbackLoading = ref(false);
const loading = ref(false);
const selectedSubmission = ref();
const score = ref();
const changesRequestedLocal = ref(false);
const emojiMenuItems = ref([{ label: 'emoji-picker', custom: true }]);
const studentSubmission = computed(() => {
  if (props.isProject) {
    return studentEnrollment.value?.summary.find(
      ({ stage: { id } }) => id == stage.value,
    );
  }
  return submissions.value.find(
    s =>
      s.participants.includes(props.exercise.student.id) &&
      s.exercise == props.exercise.exercise.id,
  );
});

const { PageContent, studentEnrollment } = useProjectSubmission(
  projectEnrollments,
  student,
  stage,
  stageData,
);

const { sendFeedbackProject } = useProjectFeedback(
  studentEnrollment.value?.id,
  student.value,
  stage.value,
  projectId.value,
);

const { setEnrollment } = useProject(
  {
    group: route.params.group as string,
    course: route.params.course as string,
  },
  projectId,
);

const scoreFocus = ref(false);

const exerciseSettings = computed(
  () => props.exercise?.exercise?.questions?.[0]?.settings,
);

const currentExerciseSettings = computed(
  () => props.exercise?.exercise?.questions[0]?.settings,
);

const onSuccessSubmission = () => {
  loading.value = false;
  if (!changesRequestedLocal.value) {
    // Create base updated submission
    const updatedSubmission = {
      ...studentSubmission.value,
    };

    // Update review_notes if feedback changed
    if (feedback.value) {
      updatedSubmission.review_notes = feedback.value;
    }

    // Update status and score if score changed
    if (score.value !== null) {
      updatedSubmission.score = score.value;
      if (updatedSubmission.status != 'changes_requested') {
        updatedSubmission.status = 'finished';
      }
    }

    // Update store with changes
    studentsStore.updateSubmission({
      submissionId: studentSubmission.value?.id,
      data: updatedSubmission,
    });

    feedback.value = '';

    toast.add({
      severity: 'success',
      summary: t('exercises_review.feedback_sent_message'),
      life: 4000,
    });
  }
};

const confirmSendForChanges = () => {
  changesRequestedLocal.value = true;
  showConfirmDialog.value = false;
  sendFeedback();
  showSuccessDialog.value = true;
};

const sendFeedback = () => {
  loading.value = true;
  isEditingFeedback.value = false;

  if (props.isProject) {
    sendFeedbackProject(feedback.value, onSuccessSubmission);
    return;
  }

  let data: any = {
    title: t('exercises_review.new_teacher_response'),
    metadata: {
      course: route.params.course,
      lesson: route.params.lesson,
      exercise: stage.value,
      page: props.exercise.exercise.questions[0].page,
    },
  };

  if (changesRequestedLocal.value) {
    data = {
      ...data,
      changes_requested: true,
      metadata: {
        ...data.metadata,
        changes_requested: true,
      },
    };
  } else {
    data = {
      ...data,
      changes_requested: false,
      notes: feedback.value,
    };
  }

  const { onSuccess } = reviewSubmission(studentSubmission.value?.id, data);
  onSuccess(async () => {
    if (
      score.value !== null &&
      score.value !== studentSubmission.value?.score
    ) {
      setScore(score.value);
      loading.value = false;
      return;
    }
    onSuccessSubmission();
  });
};

watch(
  () => showSuccessDialog.value,
  newValue => {
    if (!newValue) {
      studentsStore.fetchSubmissionsByLesson(route.params.group, lessonId);
      changesRequestedLocal.value = false;
    }
  },
);

const studentCollaborationRecord = computed(() =>
  studentSubmission.value?.records.filter(
    record => !record.userId || record.userId === props.exercise.student.id,
  ),
);

const currentExerciseType = computed(() =>
  getBlockSettingsType(currentExerciseSettings.value?.type),
);

const currentExerciseDifficulty = computed(
  () => blockDifficultyType[props.exercise?.exercise?.difficulty ?? 'easy'],
);

const MAX_SCORE = 120;

const isAIFeedback = ref(false);
const messageVisible = ref(false);
const feedback = ref(studentSubmission.value?.review_notes);

let timeoutId;

const updateScore = () => {
  nextTick(() => {
    scoreFocus.value = false;
    if (score.value == null || score.value == studentSubmission.value?.score) {
      score.value = studentSubmission.value.score ?? null;
      return;
    }

    if (score.value > 100) {
      messageVisible.value = true;
    }

    if (score.value > MAX_SCORE) {
      score.value = MAX_SCORE;
    }
    if (!props.isProject) {
      const { onSuccess } = setSubmissionScore(studentSubmission.value?.id, {
        score: score.value,
      });
      onSuccess(() => {
        studentsStore.fetchEnrollments({
          group: route.params.group,
          course: route.params.course,
        });
        const updatedSubmission = {
          ...studentSubmission.value,
        };

        // Update review_notes if feedback changed
        // if (feedback.value) {
        //   updatedSubmission.review_notes = feedback.value;
        // }

        // Update status and score if score changed
        if (score.value !== null) {
          updatedSubmission.score = score.value;
          if (updatedSubmission.status != 'changes_requested') {
            updatedSubmission.status = 'finished';
          }
        }

        // Update store with changes
        studentsStore.updateSubmission({
          submissionId: studentSubmission.value?.id,
          data: updatedSubmission,
        });

        toast.add({
          severity: 'success',
          summary: t('exercises_review.score_updated_message'),
          life: 4000,
        });

        if (score.value <= 100) return;

        timeoutId = setTimeout(() => {
          messageVisible.value = false;
        }, 2000);
      });
    } else {
      const { onSuccess } = setProjectScore({
        project: projectId.value,
        enrollment: studentEnrollment.value?.id || '',
        course: route.params.course as string,
        score: score.value,
        stage: stage.value,
      });
      onSuccess(() => {
        setEnrollment();

        timeoutId = setTimeout(() => {
          messageVisible.value = false;
        }, 2000);
      });
    }
  });
};

const aiReviewBus = useEventBus('getAIreview');
const aiFeedbackFinishedBus = useEventBus('finishedAIFeedback');

aiReviewBus.on(async () => {
  if (
    isAiableExercise.value && !selectedSubmission.value &&
    !selectedSubmission.value &&
    (studentSubmission.value?.status === 'awaiting_review' ||
      studentSubmission.value?.status === 'changes_requested')
  ) {
    if (
      // studentSubmission.value?.status === 'changes_requested' ||
      (studentSubmission.value?.review_notes && !studentSubmission.value?.score)
    ) {
      isEditingFeedback.value = true;
    }

    await getAIFeedback();
  }
});
const clearAndGetAiFeedback = async () => {
  localStorage.removeItem(`submission_${studentSubmission.value?.id}`);
  await getAIFeedback();
};

const getAIFeedback = async () => {
  aiFeedbackLoading.value = true;
  aiError.value = false;
  // Check if feedback exists in localStorage
  const storedFeedback = localStorage.getItem(
    `submission_${studentSubmission.value?.id}`,
  );
  if (storedFeedback) {
    const parsedFeedback = JSON.parse(storedFeedback);

    // Add artificial delay for loading state
    // await new Promise(resolve => setTimeout(resolve, 500));

    feedback.value = parsedFeedback.aiFeedback;
    score.value = parsedFeedback.score;
    isAIFeedback.value = true;
    aiFeedbackLoading.value = false;
    aiFeedbackFinishedBus.emit();
    return;
  }

  const { onSuccess, onError } = reviewFeedbackAI(studentSubmission.value?.id);
  onSuccess(aiFeedback => {
    const feedbackRes: AIFeedbackResponse = aiFeedback.data;
    feedback.value = feedbackRes.feedback;
    score.value = feedbackRes.score;
    isAIFeedback.value = true;
    aiFeedbackLoading.value = false;

    // Store feedback in localStorage
    localStorage.setItem(
      `submission_${studentSubmission.value?.id}`,
      JSON.stringify({
        aiFeedback: feedbackRes.feedback,
        score: feedbackRes.score,
      }),
    );

    aiFeedbackFinishedBus.emit();
  });
  onError(() => {
    aiFeedbackLoading.value = false;
    aiError.value = true;
    aiFeedbackFinishedBus.emit();
  });
};

onUnmounted(() => {
  if (timeoutId) {
    clearTimeout(timeoutId);
  }
});

const BlockPreview = props => {
  const submissions = isEmpty(studentSubmission.value)
    ? []
    : studentSubmission.value.records;
  const state =
    getStudentSubmissionAnswer(props.block) ||
    toState(props.block, submissions);
  const blockPreview = renderBlock({
    editable: false,
    block: props.block,
    state,
    review: true,
  });

  return h(
    'div',
    {
      dir: courseLocale.value !== 'he' ? 'ltr' : 'rtl',
      class:
        'block flex flex-col items-start justify-start space-y-3 text-start py-5 h-full w-full bg-secondary-75 w-full max-w-full overflow-x-auto',
    },
    blockPreview,
  );
};
BlockPreview.props = {
  block: Object,
};

const changesRequested = computed(() =>
  ['changes_requested', 'pending_changes'].includes(
    studentSubmission.value?.status,
  ),
);

const showScoreField = computed(() => {
  if (isProject.value) {
    return studentSubmission.value?.status !== 'in_progress';
  }
  return (
    studentSubmission.value &&
    !['pending', 'pending_changes'].includes(studentSubmission.value.status) &&
    studentSubmission.value.type !== 'collaboration' &&
    !props.exercise.exercise.questions?.some(
      q =>
        q.settings?.submitByGroup == true || q.name == 'decision_making_game',
    )
  );
});

watch(
  () => [studentSubmission.value],
  () =>
    (score.value = ['finished', 'changes_requested'].includes(
      studentSubmission.value?.status,
    )
      ? studentSubmission.value?.score
      : null),
  { immediate: true },
);

const currentReviewNotes = computed(() =>
  selectedSubmission.value
    ? studentSubmission.value?.history[selectedSubmission.value.code]
    : studentSubmission.value,
);

watch(
  () => [selectedSubmission.value],
  () => {
    if (selectedSubmission.value) {
      // When viewing historical submission
      score.value = selectedSubmission.value.score;
    } else if (isAIFeedback.value) {
      // When returning to current submission, preserve AI score if it exists
      const storedFeedback = localStorage.getItem(
        `submission_${studentSubmission.value?.id}`
      );
      if (storedFeedback) {
        const parsedFeedback = JSON.parse(storedFeedback);
        score.value = parsedFeedback.score;
      }
    } else {
      // Default case - use submission score
      score.value = studentSubmission.value?.score;
    }
  },
);

const scoreColor = computed(() =>
  getScoreColor(
    score.value,
    true,
    !selectedSubmission.value ? studentSubmission.value?.status : null,
  ),
);

const historyOptions = computed(() =>
  studentSubmission.value?.history?.map((h, index) => ({
    title: `${index + 1}. הגשה ${numbersMapping[index + 1] ?? ''}`,
    code: index,
    score: h.score,
  })),
);
const clearSelectedSubmission = e => {
  e.preventDefault();
  e.stopPropagation();
  selectedSubmission.value = null;
};
const getStudentSubmissionAnswer = block => {
  const record = selectedSubmission.value
    ? studentSubmission.value['history'][selectedSubmission.value.code]
    : studentSubmission.value;
  const submission = record?.records?.find(
    record =>
      record.question === block.id &&
      (!record.userId || record.userId === props.exercise.student.id),
  )?.answer;

  return submission;
};

const processFromSettings = questionBlock => {
  const {
    exercise = null,
    question = null,
    other = [],
  } = get(questionBlock, 'settings.from', {});
  const id = questionBlock.id;
  if (isEmpty(exercise) && isEmpty(other)) {
    return questionBlock;
  }
  const fromBlocks = [
    ...(exercise ? [{ id, exercise, question }] : []),
    ...other.map(otherItem => ({ ...otherItem, id })),
  ];
  fromBlocks.forEach(fromBlock => {
    const matchedAnswers = getMatchedAnswers(fromBlock, submissions.value);
    if (isEmpty(matchedAnswers)) {
      return questionBlock;
    }
    matchedAnswers.forEach(answer => {
      const previousBlock = props.exercise.exercise.questions.find(
        q => q.id === fromBlock.question,
      ) || {
        name: answer.type,
        content: answer.answer,
      };
      const content = toContent({
        block: questionBlock,
        submissions: answer.answer,
        previousBlock,
        type: fromBlock.type,
      });
      if (!isEmpty(content)) {
        Object.assign(questionBlock.content, content);
      }
    });
  });
  return questionBlock;
};

const { toContent } = useStateMapper();

const getMatchedAnswers = (fromBlock: any, submissions: any[]) =>
  submissions.flatMap(submission =>
    submission.exercise === fromBlock.exercise &&
    submission.participants.includes(props.exercise.student.id)
      ? submission.records.filter(
          record => record.question === fromBlock.question,
        )
      : [],
  );

const questions = computed(() => props.exercise.exercise?.questions || []);

const updatedQuestionList = computed(() => {
  if (!submissions.value) {
    return props.exercise.exercise.questions;
  }
  const questions = JSON.parse(
    JSON.stringify(props.exercise.exercise.questions),
  );
  const result = questions.map((questionBlock: any) => {
    return processFromSettings(questionBlock);
  });
  return result;
});

const setScore = (newScore: number) => {
  score.value = newScore;
  nextTick(() => {
    updateScore();
  });
};

const isAiableExercise = computed(
  () =>
    questions.value &&
    !isProject.value &&
    questions.value.every(question => question.name === 'free_text' || question.name === 'textarea'),
);

watch(
  () => isEditingFeedback.value,
  () => {
    // if (!newValue) {
    //   studentsStore.updateSubmission({
    //     submissionId: studentSubmission.value?.id,
    //     data: {
    //       ...studentSubmission.value,
    //       status: 'finished'
    //     }
    //   });
    //   changesRequestedLocal.value = false;
    // }
  },
);
</script>
<style>
.emoji-picker {
  box-shadow: none !important;
  border-radius: 0 !important;
}

.am-block {
  @apply scale-90 -ms-3 pointer-events-none;
}

.am-block:has(.fc-event) {
  @apply scale-95 pointer-events-auto;
}

.am-block .form-item > h5,
.am-block .form-item > p {
  @apply text-secondary-900 text-start;
}

.am-block .bank-button,
.am-block .bank-button ~ .sticky {
  @apply hidden;
}

.tags-input {
  @apply pointer-events-auto;
}

.play-button,
.file-link,
.tag-box,
.progress-tab,
.drag-box {
  @apply pointer-events-auto;
}

.tag-input,
.fc-event-draggable,
.drag-box .item {
  @apply pointer-events-none;
}

.tag-box input {
  @apply hidden;
}

.remove-file {
  @apply hidden pointer-events-none;
}

.block[dir='ltr'] .editor-content p.is-editor-empty:first-child::before {
  float: left;
}

.flex.gap-1.border-e.border-secondary-300.px-1:nth-child(3) {
  @apply hidden;
}
</style>
